import React, {useEffect, useState, useCallback} from 'react';
//import {BackHandler} from 'react-native';

import {BoardsService} from '../services/network';
import {useAuth, useConfiguration, useTheme} from '../hooks';
import {Loader} from '../components';
import BoardItem from './BoardItem';

import {ButtonItem, ImageItem, VideoItem, UserInputItem} from './boardItems';
import useBoardList from '../hooks/useBoardList';
import {FPSCounter} from '../utils/FPSCounter';
import {useSnackbar} from "notistack";
import {useParams, useHistory} from "react-router-dom";

const BoardItems = {
    button: ButtonItem,
    image: ImageItem,
    video: VideoItem,
    'user-input': UserInputItem,
};

function triggerMacro(tricasterIp, name, id) {
    const macroUrl = `http://${tricasterIp}/v1/shortcut?name=play_macro_byid&value=${id}`;
    return fetch(macroUrl, {method: 'GET'});
}

function triggerDatalink(tricasterIp, key, value) {
    const macroUrl = `http://${tricasterIp}/v1/datalink?key=${encodeURIComponent(
        key,
    )}&value=${encodeURIComponent(value)}`;
    return fetch(macroUrl, {method: 'GET'});
}

function useBoardConfiguration(id, defaultConfig) {
    const [loading, setLoading] = useState(true);
    const {authToken} = useAuth();
    const [configuration, setConfiguration] = useState(defaultConfig || null);

    function reload() {
        BoardsService.getBoardConfiguration(id, authToken)
            .then(response => {
                if (response.status === 200) {
                    setConfiguration(response.data);
                }
            })
            .finally(() => setLoading(false));
    }
    useEffect(() => {
        BoardsService.getBoardConfiguration(id, authToken)
            .then(response => {
                if (response.status === 200) {
                    setConfiguration(response.data);
                }
            })
            .finally(() => setLoading(false));
        const interval = setInterval(
            () =>
                BoardsService.getBoardConfiguration(id, authToken).then(
                    response => {
                        if (response.status === 200) {
                            setConfiguration(response.data);
                        }
                    },
                ),
            3000,
        );
        return () => {
            clearInterval(interval);
        };
    }, [id, authToken]);

    const updateItems = useCallback(items => {
        setConfiguration(configurationPrev => ({
            ...configurationPrev,
            items: items,
        }));
    }, []);

    return {
        configuration,
        loading,
        updateItems,
        reload,
    };
}

function Board({defaultBoard, defaultConfig}) {
    const { enqueueSnackbar } = useSnackbar();

    const {tricasterUri} = useConfiguration();
    const [propertiesOverride, setPropertiesOverride] = useState([]);
    const {authToken} = useAuth();
    const {boards} = useBoardList(true);
    const history = useHistory();
    const {reload} = useConfiguration();
    let { id: BoardId } = useParams();
    console.log(defaultBoard);
    const board = defaultBoard || boards.find(board => board.id === BoardId);
    const id = board && board.id;
    const {
        configuration,
        loading,
        updateItems,
        reload: reloadConfiguration,
    } = useBoardConfiguration(id, defaultConfig);

    useEffect(() => {
        // const listener = BackHandler.addEventListener(
        //     'hardwareBackPress',
        //     () => {
        //         localStorage.setItem('currentBoard', '');
        //     },
        // );
        // return () => {
        //     listener.remove();
        // };
        //TODO restore this. maybe using history insted of localstorage?
    });

    if (!board) {
        return null;
    }

    function doAction(action) {
        switch (action.type) {
            case 'none':
                break;
            case 'link':
                localStorage.setItem('currentBoard', action.id);
                history.push('/board/' + action.id);
                break;
            case 'macro':
                triggerMacro(tricasterUri, action.name, action.id)
                    .then(() => {
                        enqueueSnackbar(`Request sent`);
                    })
                    .catch(() => {
                        enqueueSnackbar('An error occured', {variant: "error"});
                    });
                break;
            case 'datalink':
                const item = configuration.items.find(
                    c => c.id === action.itemId,
                );
                const itemPropsOverride = propertiesOverride.find(p => {
                    if (!item) {
                        return false;
                    }
                    return p.itemId === item.id;
                });
                let inputValue;
                if (!item) {
                    inputValue = ' ';
                } else {
                    const itemProperties = itemPropsOverride
                        ? {
                              ...item.properties,
                              ...itemPropsOverride,
                          }
                        : item.properties;

                    inputValue = itemProperties.value;
                }
                triggerDatalink(tricasterUri, action.name, inputValue)
                    .then(() => {
                        enqueueSnackbar(`Request sent`);
                    })
                    .catch(() => {
                        enqueueSnackbar('An error occured', {variant: "error"});
                    });
                break;
            case 'edit-props':
                onItemPropertiesChange(action.itemId, action.properties);
                break;
            default:
            // Nothing to do
        }
    }

    function triggerAction(item) {
        const {action: action1, actionRevert} = item;
        let actions = action1;
        if (item.isTwoSteps && item.currentStep === 2) {
            actions = actionRevert;
        }
        // Updated this way because there was a version in production before the action was an array.
        // deprecated
        if (Array.isArray(actions)) {
            actions
                .filter(a => a.type !== 'none')
                .forEach(action => {
                    doAction(action);
                });
        } else if (actions !== null && actions !== undefined) {
            doAction(actions);
        }
        if (item.isTwoSteps) {
            BoardsService.toggleItemCurrentStep(board.id, item, authToken);
            updateItems(
                configuration.items.map(itemtmp =>
                    itemtmp.id === item.id
                        ? {...item, currentStep: (item.currentStep % 2) + 1}
                        : itemtmp,
                ),
            );
        }
    }

    function onItemPropertiesChange(itemId, newProperties) {
        setPropertiesOverride(
            updatePropertiesOverride(propertiesOverride, itemId, newProperties),
        );
    }

    const backgroundColor = configuration
        ? configuration.background
        : '#ffffff';
    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor
        }}>
            {loading && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display:"flex",
                        alignItems: 'center', justifyContent: 'center'
                    }}>
                    <Loader />
                </div>
            )}
            {configuration &&
                configuration.items &&
                configuration.items.map((item, i) => {
                    const itemPropsOverride = propertiesOverride.find(
                        p => p.itemId === item.id,
                    );
                    let properties = item.properties;
                    if (item.isTwoSteps && item.currentStep === 2) {
                        properties = item.propertiesAlt;
                    }
                    let itemProperties = itemPropsOverride
                        ? {
                              ...properties,
                              ...itemPropsOverride,
                          }
                        : properties;

                    const Content = BoardItems[item.type];

                    const actions =
                        item.action && Array.isArray(item.action)
                            ? item.action.filter(a => a.type !== 'none')
                            : [];

                    return (
                        <BoardItem
                            key={item.id}
                            boardColumns={board.columns}
                            boardRows={board.rows}
                            x={item.position.x}
                            y={item.position.y}
                            width={item.position.width}
                            height={item.position.height}
                            // We add onPress event only if an action is configured
                            onPress={
                                actions.length > 0 &&
                                (() => triggerAction(item))
                            }
                            Item={Content}
                            itemProperties={itemProperties}
                            onItemPropertiesChange={newProperties =>
                                onItemPropertiesChange(item.id, newProperties)
                            }
                        />
                    );
                })}
        </div>
    );
}

function updatePropertiesOverride(propertiesOverride, itemId, properties) {
    let newPropertiesOverride = [...propertiesOverride];

    let iProps = newPropertiesOverride.findIndex(p => p.itemId === itemId);
    let newItemProps = iProps > -1 ? newPropertiesOverride[iProps] : {itemId};
    newItemProps = {
        ...newItemProps,
        ...properties,
    };

    if (iProps > -1) {
        newPropertiesOverride[iProps] = newItemProps;
    } else {
        newPropertiesOverride.push(newItemProps);
    }
    return newPropertiesOverride;
}

export default function({navigation, defaultBoard, defaultConfig}) {
    return (
        <FPSCounter>
            <Board
                navigation={navigation}
                defaultBoard={defaultBoard}
                defaultConfig={defaultConfig}
            />
        </FPSCounter>
    );
}
