import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            message: '',
        };
    }

    componentDidCatch(err, errInfo) {
        //console.log(err);
        //console.log(errInfo);
        this.setState({
            error: true,
            message: errInfo.componentStack.toString(),
        });
    }

    render() {
        console.log(this.state.error);
        if (this.state.error) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: 16,
                            padding: 10,
                        }}>
                        <span>Une erreur est survenue.</span>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
