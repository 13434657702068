import React from 'react';
import {
    BrowserRouter as BaseRouter,
    Switch,
    Route, Redirect,
} from "react-router-dom";
import {useAuth} from './hooks';

import Home from './views/Home';
import Board from './views/Board';
import Login from './views/Login';
import Configuration from './views/Configuration';


function Router() {
    return (
        <BaseRouter>
            <Switch>
                <Route path="/configuration"><Configuration /></Route>
                <Route path="/login"><Login /></Route>
                <PrivateRoute exact path="/"><Home /></PrivateRoute>
                <PrivateRoute path="/board/:id"><Board /></PrivateRoute>
            </Switch>
        </BaseRouter>
    );
}


function PrivateRoute({children, ...rest}) {

    const {isLoggedIn, user} = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default Router;
