import React, {useState, useCallback, useEffect, useContext, useRef} from 'react';
import WebSocketManager from '../../services/technical/WebSocketManager';
import base64 from 'base64-js';
import {useConfiguration, useTheme} from '../../hooks';
import {FPSCounter, FPSCounterContext} from '../../utils/FPSCounter';
import {drawImageProp} from "../../utils/ctxUtils";

function getUrl(IpTricaster, name, quality, imageX, imageY) {
    return (
        'ws://' +
        IpTricaster +
        '/v1/video_notifications?name=' +
        name +
        '&xres=' +
        imageX +
        '&yres=' +
        imageY +
        '&q=10'
    );
}
async function test(msg) {

    // const text = await (new Response(msg.data)).text();
    // console.log(text);

}
const Video = ({playing, name, quality, imageX, imageY}) => {
    const canvas = useRef(null);
    const {tricasterUri} = useConfiguration();
    const {mark} = useContext(FPSCounterContext);

    const onUpdate = useCallback(
        msg => {
            if (!playing) {
                return;
            }
            mark();
            requestAnimationFrame(() => {
                try {

                    var fr = new FileReader();
                    fr.onload = function () {
                        // this variable holds your base64 image data URI (string)
                        // use readAsBinary() or readAsBinaryString() below to obtain other data types
                        var ctx = canvas.current.getContext('2d');
                        var image = new Image();
                        image.onload = function () {
                            drawImageProp(ctx, image, 0, 0, canvas.current.width, canvas.current.height);
                        };
                        image.src = fr.result.replace('application/octet-stream', 'image/jfif');
                    };
                    fr.readAsDataURL(msg.data);
                } catch (e) {
                }
            });
        },
        [playing, mark, canvas],
    );
    useEffect(() => {
        const socket = WebSocketManager.connect(
            getUrl(tricasterUri, name, quality, imageX, imageY),
            onUpdate,
            15000,
            true,
        );
        return () => {
            socket.forceClose();
        };
    }, [tricasterUri, name, quality, imageX, imageY, onUpdate]);

    return (
        <div
            style={{
                position: 'relative',
                height: '100%',
                width: '100%',
            }}>
            <canvas ref={canvas}
                    style={{
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                    }}>
            </canvas>
        </div>
    );
};

function VideoItem({
    style,
    color,
    name,
    quality,
    width,
    height,
    numberInHistory,
    ...otherProps
}) {
    const {colors} = useTheme();
    const [playing, setPlaying] = useState(true);
    const imageX = Math.round(width / 0.8);
    const imageY = Math.round(imageX / 1.77);
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                padding: 0,
                backgroundColor: '#f0f0f0',
                marginBottom: 8,
                borderWidth: 3,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                borderColor: color || colors.primary,
                ...style,
            }}
            onClick={() => setPlaying(!playing)}>
            <FPSCounter>
                <Video
                    playing={playing}
                    name={name}
                    quality={quality}
                    imageX={imageX}
                    imageY={imageY}
                    numberInHistory={numberInHistory}
                    {...otherProps}
                />
            </FPSCounter>
        </div>
    );
}

export default VideoItem;
