import NetworkService from './networkService';

import ConfigurationContext from '../../contexts/ConfigurationContext';

function getConfiguration(authToken) {
  return NetworkService.fetchJSON(
    `${ConfigurationContext.getApiUri()}/configuration`,
    {
      method: 'GET',
      headers: {
        'x-auth-token': authToken,
      },
    },
  );
}

const configurationService = {
  getConfiguration,
};

export default configurationService;
