import React from 'react';

function ButtonItem({
    text,
    textColor,
    backgroundColor,
    backgroundImage,
    textAlign,
    fontSize,
    fontFamily,

    stickLeft,
    stickRight,
    stickTop,
    stickBottom,
    textVerticalAlign,
}) {
    let alignItems;
    switch (textAlign) {
        case 'left':
            alignItems = 'flex-start';
            break;
        case 'right':
            alignItems = 'flex-end';
            break;
        case 'center':
            alignItems = 'center';
            break;
        default:
        // Nothing
    }
    let justifyContent;
    switch (textVerticalAlign) {
        case 'top':
            justifyContent = 'flex-start';
            break;
        case 'bottom':
            justifyContent = 'flex-end';
            break;
        case 'middle':
            justifyContent = 'center';
            break;
        default:
            justifyContent = 'center';
        // Nothing
    }
    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                paddingLeft: stickLeft ? 0 : 4,
                paddingRight: stickRight ? 0 : 4,
                paddingTop: stickTop ? 0 : 4,
                paddingBottom: stickBottom ? 0 : 4,
                boxSizing: "border-box",
            }}>
            <button
                style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderTopLeftRadius: stickTop|| stickLeft ? 0 : 4,
                    borderBottomLeftRadius: stickBottom || stickLeft ? 0 : 4,
                    borderTopRightRadius: stickTop || stickRight ? 0 : 4,
                    borderBottomRightRadius: stickBottom || stickRight ? 0 : 4,
                    backgroundColor: backgroundColor || '#aaaaaa',
                    alignItems: alignItems || 'center',
                    justifyContent: justifyContent || 'center',
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                }}>
                {backgroundImage && (
                    <img
                        src={{
                            uri: backgroundImage,
                        }}
                        //resizeMode="cover" TODO a tester
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderTopLeftRadius: stickTop || stickLeft ? 0 : 4,
                            borderBottomLeftRadius: stickBottom || stickLeft ? 0 : 4,
                            borderTopRightRadius: stickTop || stickRight ? 0 : 4,
                            borderBottomRightRadius: stickBottom || stickRight ? 0 : 4,
                        }}
                    />
                )}
                <span
                    style={{
                        color: textColor || '#ffffff',
                        fontSize: fontSize
                            ? parseInt(fontSize, 10)
                            : 20,
                        fontFamily,
                    }}>
                    {text}
                </span>
            </button>
        </div>
    );
}

export default ButtonItem;
