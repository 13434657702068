function fetchJSON(url, {body, headers, ...otherProps}) {
  const options = {
    ...otherProps,
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(response => {
      return new Promise((resolve, reject) => {
        response.json().then((json => {
            resolve({
                status: response.status,
                data: json,
            });
        }));
      })
  });
}

const networkService = {
  fetchJSON,
};

export default networkService;
