import React from 'react';

import {Input} from '../../components';

function UserInputItem({
  value,
  label,
  placeholder,
  borderColor,

  textColor,
  fontFamily,

  stickLeft,
  stickRight,
  stickTop,
  stickBottom,

  onPropertiesChange,
}) {
  return (
    <div
      style={{
          width: '100%',
          height: '100%',
          paddingLeft: stickLeft ? 0 : 4,
          paddingBottom: stickBottom ? 0 : 6,
          paddingTop: stickTop ? 0 : 4,
          paddingRight: stickRight ? 0 : 4,
      }}>
      <Input
        label={label}
        placeholder={placeholder}
        onChangeText={v => onPropertiesChange({value: v})}
        value={value}
        color={borderColor}
        style={{
            width: '100%',
            height: '100%',

            borderTopLeftRadius: stickLeft || stickTop ? 0 : 4,
            borderTopRightRadius: stickRight || stickTop ? 0 : 4,
        }}
        inputStyle={{color: textColor, fontFamily}}
      />
    </div>
  );
}


export default UserInputItem;
