import React, {useState, useEffect} from 'react';
import {useDimensions} from '../hooks';
import {Transition} from "react-transition-group";

const DEFAULT_COLUMNS = 24;
const DEFAULT_ROWS = 24;

const duration = 400;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
};

const transitionStyles = {
    entering: { opacity: 0 },
    entered:  { opacity: 1},
};
/**
 * A generic component that position an item
 * on the board.
 */
export default React.memo(function BoardItem({
    boardColumns,
    boardRows,

    x,
    y,
    width,
    height,

    onPress,

    noTransition,

    children,

    Item,
    itemProperties,
    onItemPropertiesChange,
}) {

    const windowDimensions = useDimensions();

    const nbColumns = boardColumns || DEFAULT_COLUMNS;
    const nbRows = boardRows || DEFAULT_ROWS;
    const ratioX = windowDimensions.width / nbColumns;
    const ratioY = windowDimensions.height / nbRows;


    const itemWidth = width * ratioX;
    const itemHeight = height * ratioY;
    const content = Item && (
        <Item
            {...itemProperties}
            width={itemWidth}
            height={itemHeight}
            onPropertiesChange={onItemPropertiesChange}
        />
    );
    if(noTransition) {
        return (onPress ? (
            <div onClick={onPress} style={{
                position: 'relative',
                width: "100%",
                height: "100%",
            }}>{content}</div>
        ) : (
            content
        ))
    }
    //appear
    return (
        <Transition
            in={true}
            timeout={duration}
            enter={true}
            appear={true}
        >
            {state => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                    position: 'absolute',
                    left: (x - 1) * ratioX,
                    top: (y - 1) * ratioY,
                    width: itemWidth,
                    height: itemHeight,
                }}>
                    {onPress ? (
                        <div onClick={onPress} style={{
                            position: 'relative',
                            width: "100%",
                            height: "100%",
                        }}>
                            <div style={{
                                position: 'relative',
                                width: "100%",
                                height: "100%",
                            }}>
                                {content}
                            </div>
                        </div>
                    ) : (
                        content
                    )}
                </div>
            )}
        </Transition>
    );
});

