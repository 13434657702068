import React from 'react';
import {useTheme} from '../hooks';

function Input({label, style, inputStyle, color, placeholder, onChangeText, value, secureTextEntry, ...otherProps}) {
  const {colors} = useTheme();
  return (
    <div style={{
      width: 300,
      paddingTop: 4,
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#f0f0f0',
      marginBottom: 8,
      borderBottomWidth: 3,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderColor: color || colors.primary,
      ...style,
    }}>
      {label && (
        <span style={{
          fontSize: 18,
          color: color || colors.primary
        }}>{label}</span>
      )}
      <input
          style={{
            height: 40,
            width: '100%',
            borderWidth: 0,
            fontSize: 18,
              backgroundColor: "inherit",
            ...inputStyle
          }}
          placeholder={placeholder}
          onChange={(e) => onChangeText(e.target.value)}
          value={value}
          type={secureTextEntry? "password" : "text"}
          {...otherProps}
      />
    </div>
  );
}

export default Input;
