import React, {useState, useEffect, useCallback} from 'react';
import useAuth from "../hooks/useAuth";
import ConfigurationService from '../services/network/configurationService';

let apiUri = null;

const defaultContext = {
    tricasterUri: '0',
    apiUri: null,
    setApiUri: () => {
    },
    loading: true,
    error: null,
    reload: () => {
    },
    withFps: false,
};

const Context = React.createContext(defaultContext);

function Provider({children}) {
    const [configuration, setConfiguration] = useState(defaultContext);
    const {authToken} = useAuth();


    const apiUriTmp = configuration.apiUri;
    useEffect(() => {
        if(!apiUriTmp) {
            apiUri = localStorage.getItem('@apiUri');
            setConfiguration((configurationPrev) => ({...configurationPrev, apiUri: apiUri}));
        }
    }, [apiUriTmp]);

    const reload = useCallback(() => {
        if (apiUriTmp === null) {
            return;
        }
        const updateConfiguration = (value) => {
            setConfiguration((configurationPrev) => {
                if (value.status === 200) {
                    if (value.data.tricasterUri !== undefined) {
                        return {
                            ...configurationPrev,
                            tricasterUri: value.data.tricasterUri,
                            loading: false,
                            error: undefined,
                        };
                    }
                    return {
                        ...configurationPrev,
                        tricasterUri: configurationPrev.tricasterUri,
                        loading: false,
                        error: 'No configuration found',
                    }
                }
                return {
                    ...configurationPrev,
                    tricasterUri: configurationPrev.tricasterUri,
                    loading: false,
                    error: 'Network error',
                };
            });
        };
        setConfiguration((configurationPrev) => ({
            ...configurationPrev,
            tricasterUri: configurationPrev.tricasterUri,
            loading: true,
        }));
        ConfigurationService.getConfiguration(authToken).then(updateConfiguration).catch((error) => {
            //console.warn('error encountered in configuration.' + apiUriTmp);
        });
    }, [apiUriTmp]);

    useEffect(() => {
        reload();
        // const interval = setInterval(reload, 60000);
        // return () => {
        //     clearInterval(interval);
        // }
    }, [apiUriTmp]);

    function setApiUri(newApiUri) {
        apiUri = newApiUri;
        setConfiguration(configurationPrev => ({...configurationPrev, apiUri: newApiUri}));
        localStorage.setItem('@apiUri', newApiUri);
    }

    function setWithFPS(value) {
        setConfiguration(configurationPrev => ({...configurationPrev, withFps: value}));
    }

    return (
        <Context.Provider
            value={{
                ...configuration,
                apiUri: configuration.apiUri,// TODO  || apiUri,
                setConfiguration,
                setApiUri,
                reload,
                setWithFPS,
            }}>
            {children}
        </Context.Provider>
    );
}

function getApiUri() {
    return apiUri;
}

const ConfigurationContext = {
    Context,
    Provider,
    Consumer: Context.Consumer,
    getApiUri: getApiUri
};

export default ConfigurationContext;
