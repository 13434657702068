import React, {useState, useEffect} from 'react';
import ErrorBoundary from './ErrorBoundary';

import {AuthContext, ConfigurationContext} from './contexts';
import Router from './Router';
import { SnackbarProvider } from 'notistack';


const App = () => {
    const [loading, setLoading] = useState(true);

    // This trick is used to avoid glitches
    // when starting the application.
    useEffect(() => {
        setTimeout(() => setLoading(false), 200);
    }, []);

    return (
        <ErrorBoundary>
            <ConfigurationContext.Provider>
                <SnackbarProvider>
                    <AuthContext.Provider>
                        {!loading && <Router />}
                    </AuthContext.Provider>
                </SnackbarProvider>
            </ConfigurationContext.Provider>
        </ErrorBoundary>
    );
};

export default App;
