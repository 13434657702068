import React from 'react';
import {useAuth, useConfiguration} from '../hooks';

import './BoardListItem.scss';

function BoardListItem({id, name, backgroundColor, opacity, onPress}) {
  const {authToken} = useAuth();
  const {apiUri} = useConfiguration();

  const imageUri = `${apiUri}/boards/${id}/image?x-auth-token=${authToken}`;

  return (
    <div
        className="home__board-list-item__container"
        style={{
            borderColor: backgroundColor,
            backgroundImage: `url("${imageUri}")`,
        }}
        onClick={onPress}
    >
        <div
            className="home__board-list-item__label"
            style={{
                backgroundColor: backgroundColor,
                opacity: opacity,
            }}
        >
          <span style={{
            color: '#ffffff',
            fontSize: 20,
          }}>{name}</span>
      </div>
    </div>
  );
}


export default BoardListItem;
