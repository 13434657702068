import React from 'react';
import {useTheme} from '../hooks';

import './ActivityIndicator.scss';

function Loader() {
  const {colors} = useTheme();
  return (
    <div style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.2)",
    }}>
        <div className="loader"></div>
      <span style={{color: colors.primary, textAlign: 'center'}}>
        Chargement...
      </span>
    </div>
  );
}

export default Loader;
