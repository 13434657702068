import {useState, useEffect} from 'react';
import {useAuth} from "./index";
import {BoardsService} from "../services/network";


export default function useBoardList(withPrivate) {
    if(!withPrivate) withPrivate = false;
    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);
    const {authToken} = useAuth();
    function reloadBoards() {
        setLoading(true);
        BoardsService.getBoards(authToken)
            .then(response => {
                if (response.status === 200) {
                    setBoards(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        reloadBoards();
    }, [authToken]);
    return {
        boards: boards.filter(board => withPrivate || !board.private),
        loading,
        reloadBoards,
    }
}
