import {useState, useEffect} from 'react';

function useDimensions() {


  const [dimension, setDimension] = useState({ width: 0, height: 0 });

  useEffect(() => {
    updateDimension();
    window.addEventListener('resize', updateDimension);
    function updateDimension() {
      setDimension({ width: window.innerWidth, height: window.innerHeight });
    }
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);
  return dimension;
}

export default useDimensions;
