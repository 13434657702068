import React, {useEffect, useRef, useState} from 'react';

import {useRefreshedUri, useConfiguration} from '../../hooks';
import {drawImageProp} from "../../utils/ctxUtils";

function getTricasterUrl(tricasterIp, name, quality, imageX, imageY) {
    return (
        'http://' +
        tricasterIp +
        '/v1/image?name=' +
        name +
        '&yres=' +
        imageY +
        '&q=10'
    );
}

function ImageItem({refresh, image, name, quality, width}) {
    const {tricasterUri} = useConfiguration();

    let uri;
    if (image) {
        uri = image;
    } else if (name) {
        const imageY = Math.round(width);
        const imageX = Math.round((imageY * 16) / 9);
        uri = getTricasterUrl(tricasterUri, name, quality, imageX, imageY);
    }

    if (!uri) {
        return null;
    }

    return (
        <div style={{
            padding: 4,
            position: 'relative',
            width: '100%',
            height: '100%',
            boxSizing: "border-box",
        }}>
            {refresh ? (
                <RefreshedImage uri={uri} interval={refresh} />
            ) : (
                <img
                    src={uri}
                     //resizeMode="cover" TODO
                     style={{
                    position: 'absolute',
                    top: 4,
                    left: 4,
                    width: 'calc(100% - 8px)',
                    height: '100%',
                    borderRadius: 4,
                }} />
            )}
        </div>
    );
}
function update(uri, canvas) {
    const timestamp = new Date().getTime();
    let newUri = uri;
    if (newUri && newUri.startsWith('http')) {
        if (!newUri.includes('?')) {
            newUri += '?';
        } else {
            newUri += '&';
        }
        newUri += `randToken=${timestamp}`;
    }
    const ctx = canvas.getContext('2d');
    // fetch(newUri).then((response) => response.blob()
    //     //ctx.drawImage(response.body, 0, 0);
    // ).then((blob) => {
    //         console.log(uri, URL.createObjectURL(blob));
    // });
    // var ctx = canvas.current.getContext('2d');
    var image = new Image();
    image.onload = function() {
        ctx.drawImage(image, 0, 0);
        drawImageProp(ctx, image, 0, 0, canvas.width, canvas.height);

    };
    image.src = newUri;
    //
    // setUris(prevUris => {
    //     let newUris = [...prevUris.uris];
    //     const newIndex =
    //         (prevUris.currentIndex + 1) % history;
    //     if (newIndex >= newUris.length) {
    //         newUris.push({
    //             timestamp,
    //             uri: newUri,
    //         });
    //     } else {
    //         delete newUris[newIndex];
    //         newUris[newIndex] = {
    //             timestamp,
    //             uri: newUri,
    //         };
    //     }
    //
    //     return {
    //         uris: newUris,
    //         currentIndex: newIndex,
    //     };
    // });
}

function RefreshedImage({uri, interval}) {
    const [realUri, setRealUri] = useState(uri);
    const canvas = useRef(null);
    useEffect(() => {
        const intervalInt = parseInt(interval, 10);
        const intervalId =
            intervalInt > 0
                ? setInterval(() => {
                    const timestamp = new Date().getTime();
                    let newUri = uri;
                    if (newUri && newUri.startsWith('http')) {
                        if (!newUri.includes('?')) {
                            newUri += '?';
                        } else {
                            newUri += '&';
                        }
                        newUri += `randToken=${timestamp}`;
                    }
                    setRealUri(newUri);
                }, intervalInt)
                : null;
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [interval, uri]);

    useEffect(() => {
        update(realUri, canvas.current)
    }, [canvas, realUri]);

    return (
        <canvas ref={canvas} style={{
            position: 'absolute',
            top: 4,
            left: 4,
            width: 'calc(100% - 8px)',
            height: '100%',
            borderRadius: 4,
        }}>
        </canvas>
    );
}


export default ImageItem;
