import NetworkService from './networkService';

import ConfigurationContext from '../../contexts/ConfigurationContext';

function postAuthToken({username, password}) {
  return NetworkService.fetchJSON(`${ConfigurationContext.getApiUri()}/auth-tokens`, {
    method: 'POST',
    body: {username, password},
  });
}

const authTokensService = {
  postAuthToken,
};

export default authTokensService;
