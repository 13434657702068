import React, {useState} from 'react';

import {Input, Button} from '../components';
import {useTheme, useConfiguration} from '../hooks';
import Switch from "@material-ui/core/Switch";
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import {useHistory} from "react-router-dom";

function Configuration() {
    const history = useHistory();
    const configuration = useConfiguration();
    const [apiUri, setApiUri] = useState(configuration.apiUri);
    const theme = useTheme();

    function onSubmit() {
        let apiUriTmp = apiUri;
        if (!apiUriTmp.includes(':')) {
            apiUriTmp = apiUriTmp + ':443';
        }
        if (!apiUriTmp.includes('://')) {
            apiUriTmp = 'https://' + apiUriTmp;
        }
        configuration.setApiUri(apiUriTmp);
    }
    return (
        <div>
            <div>
                <div style={{
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 100,
                    display: "flex",
                    paddingBottom: 100,
                }}>
                    <div style={{
                        width: 600,
                    }}>
                        <div style={{
                            marginBottom: 32,
                        }}>
                            <div style={{
                                fontSize: 32,
                            }}>Configuration</div>
                            <div
                                style={{
                                    fontSize: 20,
                                    color: theme.colors.mediumGray,
                                }}>
                                Vous devez configurer l'application avant de pouvoir l'utiliser
                            </div>
                        </div>

                        <Input
                            label="Adresse de l'API"
                            placeholder="http://"
                            style={{
                                marginBottom: 32,
                                width: '100%',
                            }}
                            onChangeText={setApiUri}
                            value={apiUri}
                        />
                        <div style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 15,
                        }}>
                            <span style={{
                                width: '75%',
                            }}>Avec FPS</span>
                            <Switch
                                checked={configuration.withFps}
                                onChange={(e, value) => configuration.setWithFPS(value)}
                                style={{
                                width: '25%',
                            }}
                            />
                        </div>

                        <Button onPress={onSubmit}>Enregistrer</Button>
                        {/*<Button onPress={() => AdminHandler.toggle()}>Administrateur</Button>*/}
                    </div>
                </div>
            </div>

            {(configuration.apiUri) && (
                <div
                    onClick={() =>history.push('/')}
                    style={{position: 'absolute', top: 32, left: 32, cursor: "pointer"}}>
                    <div style={{padding: 8}}>
                        <ArrowLeftOutlined size={30} color={theme.colors.mediumGray}/>
                    </div>
                </div>
            )}
        </div>
    );
}


export default Configuration;
