import React, {useState} from 'react';


function Menu({trigger, triggerStyle, children, containerStyle}) {
  const [visible, setVisible] = useState(false);
  function toggleMenu() {
    setVisible(!visible);
  }
  return (
    <React.Fragment>
      <div onClick={toggleMenu} style={triggerStyle}>
        {trigger}
      </div>
      {visible && (
        <div onClick={toggleMenu} style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: 0,
            bottom: 0,
        }}>
          <div></div>
        </div>
      )}
      {visible && (
        <div style={{
          backgroundColor: '#ffffff',
          borderRadius: 4,
          borderWidth: 1,
          borderColor: '#aaaaaa',
          ...containerStyle,
        }}>{children}</div>
      )}
    </React.Fragment>
  );
}

function Item({onPress, children}) {
  return (
    <div onClick={onPress} style={{
      minWidth: 180,
      height: 40,
      padding: 8,
      borderBottomWidth: 1,
      borderColor: '#eeeeee',
    }}>
      <div>
        <span style={{
          fontSize: 18,
        }}>{children}</span>
      </div>
    </div>
  );
}

export default {Menu, Item};
