import React, {useState, useCallback } from 'react';
import {useConfiguration} from '../hooks';
import useInterval from '../hooks/useInterval';
const performanceNow =
    global.nativeQPLTimestamp ||
    global.nativePerformanceNow ||
    require('fbjs/lib/performanceNow');

const FPSCounterContext = React.createContext();

const Counter = ({currentFPS}) => {
    return (
        <div
            style={{
                height: 25,
                width: 75,
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: 'white',
                color: 'black',
            }}>
            <span>{Math.round(currentFPS * 100) / 100}</span>
        </div>
    );
}

const FPSCounter = ({children}) => {
    const {withFps} = useConfiguration();
    const [currentFPS, setCurrentFPS] = useState(0);
    const [count, setCount] = useState(0);
    const [start, setStart] = useState(performanceNow());

    useInterval(() => {
        if (!withFps) {
            return;
        }
        const end = performanceNow();
        setCurrentFPS(count / ((end - start) / 1000));
        setCount(0);
        setStart(performanceNow());
    }, 1000);

    const mark = useCallback(() => {
        setCount(cnt => cnt + 1);
    }, []);

    return (
        <FPSCounterContext.Provider
            value={{
                mark,
            }}>
            {children}
            {withFps && <Counter currentFPS={currentFPS} />}
        </FPSCounterContext.Provider>
    );
};
export {FPSCounterContext, FPSCounter};
