import React from 'react';
import {useTheme} from '../hooks';
import Loader from "./Loader";


function Button({onPress, children, style, textStyle, loading}) {
  const {colors} = useTheme();
  return (
      <div
          onClick={onPress}
          style={{
          backgroundColor: colors.primary,
          height: 50,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 4,
          paddingLeft: 8,
          paddingRight: 8,
          cursor: "pointer",
              display: "flex",
          ...style
      }}>
        {loading ? (
            <Loader/>
        ) : (
          <span style={{
            color: '#ffffff',
            fontSize: 20,
            ...textStyle,
          }}>{children}</span>
        )}
      </div>
  );
}

export default Button;
