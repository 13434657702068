import NetworkService from './networkService';

import ConfigurationContext from '../../contexts/ConfigurationContext';

function getBoards(authToken) {
  return NetworkService.fetchJSON(`${ConfigurationContext.getApiUri()}/boards`, {
    method: 'GET',
    headers: {
      'x-auth-token': authToken,
    },
  });
}

function getBoardConfiguration(id, authToken) {
  return NetworkService.fetchJSON(`${ConfigurationContext.getApiUri()}/boards/${id}/configuration`, {
    method: 'GET',
    headers: {
      'x-auth-token': authToken,
    },
  });
}

function toggleItemCurrentStep(id, item, authToken) {
  return NetworkService.fetchJSON(`${ConfigurationContext.getApiUri()}/boards/${id}/toggle-step/${item.id}`, {
    method: 'PATCH',
    headers: {
      'x-auth-token': authToken,
    },
  });
}

const boardsService = {
  getBoards,
  getBoardConfiguration,
  toggleItemCurrentStep,
};

export default boardsService;
