import React, {useEffect, useState} from 'react';
import LoginService from '../services/technical/LoginService';

const Context = React.createContext({
    isLoggedIn: false,
    authToken: null,
    user: null,
    setAuthToken: () => {},
    logout: () => {},
});

function Provider({children}) {
    const [authToken, setAuthToken] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        LoginService.loginIfRemembered(setUser, setAuthToken);
    }, []);

    function logout() {
        LoginService.logout(setUser, setAuthToken);
    }


    return (
        <Context.Provider
            value={{
                isLoggedIn: !!authToken,
                authToken,
                setAuthToken,
                user,
                setUser,
                logout,
            }}>
            {children}
        </Context.Provider>
    );
}



const AuthContext = {
    Context,
    Provider,
    Consumer: Context.Consumer,
};

export default AuthContext;
