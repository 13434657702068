import React, { useEffect, useCallback} from 'react';

import {useTheme, useAuth, useConfiguration, useDimensions} from '../hooks';
import {useHistory} from 'react-router-dom';

import logo from '../images/careprod_logo_color.png';

import {DropdownMenu} from '../components';

import BoardListItem from './BoardListItem';
import useBoardList from '../hooks/useBoardList';
import PullDownAction from "../components/PulldownAction";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";

function Home() {
    const history = useHistory();
    const {reload} = useConfiguration();
    const {boards, reloadBoards} = useBoardList(true);
    const {logout, user} = useAuth();
    const theme = useTheme();

    const goToBoard = useCallback(
        board => {
            localStorage.setItem('currentBoard', board.id);
            history.push('/board/' + board.id);
        },
        [history],
    );

    useEffect(() => {
        console.log(boards);
        if (!boards) {
            return;
        }
        const privateBoards = boards.filter(boardTmp => !boardTmp.private);
        const value = localStorage.getItem('currentBoard');
        if (value) {
            const boardFound = boards.find(
                boardTmp => boardTmp.id === value,
            );
            if (boardFound) {
                goToBoard(boardFound);
                return;
            }
        }
        if (privateBoards.length === 1) {
            goToBoard(privateBoards[0]);
        }
    }, [boards, goToBoard]);

    const {height} = useDimensions();


    return (
        <div>
            <PullDownAction
                onAction={() => {
                    reload();
                    reloadBoards();
                }}
            />
            <div style={{
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: 100,
                paddingBottom: 50,
                minHeight: height,
                display: "flex",
            }}>
                <div style={{
                    width: 600,
                }}>
                    {boards
                        .filter(boardTmp => !boardTmp.private)
                        .map((board, i) => (
                                <BoardListItem
                                    key={board.id}
                                    onPress={() => goToBoard(board)}
                                    {...board}
                                />
                        ))}
                </div>
            </div>

            <img
                alt={"logo"}
                src={logo}
                style={{
                    width: 120,
                    height: 40,
                    position: 'absolute',
                    bottom: 6,
                    right: 32,
                    resizeMode: 'contain',
                }}
            />

            <DropdownMenu.Menu
                trigger={
                    <div style={{padding: 8}}>
                        <SettingOutlined
                            name="settings"
                            size={30}
                            color={theme.colors.mediumGray}
                        />
                    </div>
                }
                triggerStyle={{position: 'absolute', top: 32, right: 32}}
                containerStyle={{position: 'absolute', top: 80, right: 32}}>
                {user.isSuperAdmin && (
                    <DropdownMenu.Item
                        onPress={() =>
                            history.push('/configuration')
                        }>
                        Configuration
                    </DropdownMenu.Item>
                )}
                <DropdownMenu.Item onPress={logout}>
                    Déconnexion
                </DropdownMenu.Item>
            </DropdownMenu.Menu>
        </div>
    );
}
export default Home;