import React, {useEffect, useState} from 'react';

import logo from '../images/careprod_logo_color.png';


import {Button, Input} from '../components';
import {useAuth, useConfiguration, useTheme} from '../hooks';
import LoginService from '../services/technical/LoginService';
import {useSnackbar} from "notistack";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import {Redirect, useHistory} from 'react-router-dom';

function Login() {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const {reload, apiUri, loading: isNotReady} = useConfiguration();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {setAuthToken, setUser} = useAuth();
    const theme = useTheme();

    function login() {
        setLoading(true);
        LoginService.login({username: username, password})
            .then(response => {
                if (response.status >= 400) {
                    if (response.data && response.data.message) {
                        enqueueSnackbar(response.data.message);
                    } else {
                        enqueueSnackbar('An error occured', {variant: "error"});
                    }
                    setLoading(false);
                } else {
                    reload();
                    setUser(response.data.user);
                    setAuthToken(response.data.authToken);
                    history.push('/');
                }
            })
            .catch(error => {
                enqueueSnackbar('An error occured', {variant: "error"});
                setLoading(false);
            });
    }
    if(!apiUri && isNotReady) {
        return (
            <Redirect
                to={{
                    pathname: "/configuration",
                }}
            />
        );
    }

    return (
        <div style={{backgroundColor: '#ffffff'}}>
            <div style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 100,
                display: "flex",
                paddingBottom: 100,
            }}>
                <img src={logo} alt={"logo"} />

                <div style={{marginTop: 32}}>
                    <Input
                        label="Identifiant"
                        style={{
                            marginBottom: 32,
                        }}
                        onChangeText={setUsername}
                        value={username}
                        //autoFocus
                    />
                    <Input
                        label="Mot de passe"
                        style={{
                            marginBottom: 32,
                        }}
                        onChangeText={setPassword}
                        value={password}
                        secureTextEntry
                    />

                    <Button onPress={login} style={{
                        width: '100%',
                    }} loading={loading}>
                        Connexion
                    </Button>
                </div>

                <span
                    onClick={() => history.push('/configuration')}
                    style={{position: 'absolute', top: 32, right: 32, cursor: "pointer"}}>
                    <div style={{padding: 8}}>
                        <SettingOutlined size={30} style={{color: theme.colors.mediumGray}}/>
                    </div>
                </span>
            </div>
        </div>
    );
}

export default Login;
