import {AuthTokensService} from '../network';

const KEY = 'auth_token_remembered';
const KEY_User = 'user_remembered';

class LoginService {
    loginIfRemembered(setUser, setAuthToken) {
        setAuthToken(localStorage.getItem(KEY));
        setUser(JSON.parse(localStorage.getItem(KEY_User)));
    }

    logout(setUser, setAuthToken) {
        setAuthToken(null);
        setUser(null);
        localStorage.removeItem(KEY);
        localStorage.removeItem(KEY_User);
        localStorage.removeItem('currentBoard');
    }

    login({username, password}) {
        return new Promise((success, reject) => {
            AuthTokensService.postAuthToken({
                username: username.trim(),
                password,
            }).then(response => {
                if (response.status < 400) {
                    if (response.data && response.data.authToken) {
                        localStorage.setItem(KEY, response.data.authToken);
                        localStorage.setItem(KEY_User, JSON.stringify(response.data.user));
                    }
                }
                success(response);
            });
        });
    }
}


export default new LoginService();
