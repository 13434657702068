//Check numpber of Sockets.

//Maj React-native .

function connect(url, onUpdate, ping, keepAlive) {
    let socket = new WebSocket(url);

    socket.keepAlive = keepAlive;
    socket.current = socket;

    socket.onopen = () => {
        if (ping > 0) {
            const pingRefresh = setInterval(() => {
                if (socket.readyState > 1) {
                    clearInterval(pingRefresh);
                } else {
                    socket.send('Ping');
                }
            }, ping);
        }
    };
    socket.onmessage = onUpdate;
    socket.onclose = () => {
        if(socket.keepAlive) {
            socket.current = connect(url, onUpdate, ping, keepAlive);
        }
    };
    socket.onerror = e => {
        if (!e.isTrusted && socket.keepAlive) {
            socket.forceClose();
            connect(url, onUpdate, ping, keepAlive);
        }
    };

    socket.forceClose = () => {
        socket.keepAlive = false;
        socket.current.close();
    }

    return socket;
}

const WebSocketManager = {
    connect,
};

export default WebSocketManager;
