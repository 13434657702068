import React from 'react';

const ThemeContext = React.createContext({
  colors: {
    primary: '#3e9ec1',
    accent: '#FF0000',
    mediumGray: '#aaaaaa',
    lightGray: '#eeeeee'
  },
});

export default ThemeContext;
